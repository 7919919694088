.add-amount{
    max-width: 300px;
    margin: 0 auto;
}

.settings{
    text-align: left;
    &__item{
        padding: 1rem 0;
        border-bottom: 1px solid rgba($white, .3);
        &:last-child{
            border: none;
        }
        & a {
            color: $white;
            font-size: 1.14rem;
            font-weight: 400;
            text-decoration: none;
            display: flex;
        }
    }
    &__icon{
        width: 24px;
        height: 24px;
        margin-right: 12px;
        &-stripe{
            background-image: url('../../assets/images/settings/stripe.png');
        }
    }
}