.layout{
    background-image: linear-gradient(to bottom, 
                        rgba($secondary, 0) 590px,
                        rgba($secondary, 1) 660px    
                      ), url('../../assets/images/background-shapes.png');
    background-repeat: repeat-x;
    // background-size: contain;
    background-position: center 0;
    background-color: $secondary;
    position: relative;
    min-height: 100vh;
    &__gradient{
        position: absolute;
        left: 0;
        right: 0;
        min-height: 420px;
        background: linear-gradient(180deg, rgba(0, 126, 81, 0.9) 0%, rgba(49, 57, 53, 0.52) 86.05%, rgba(52, 52, 52, 0) 100%);
    }
    &__content{
        &-fill{
            min-height: calc(100vh - 60px);
        }
    }
}
@media screen and (min-width: $md-brakpoint) {
    .layout{
        background-repeat: repeat-x;
        background-image: linear-gradient(to bottom, 
            rgba($secondary, 0) 590px,
            rgba($secondary, 1) 660px,
            transparent 100%
        ), 
        linear-gradient(to right,         
            rgba($secondary, 1) 0,
            rgba($secondary, 0) 35%,
            rgba($secondary, 0) 55%,
            rgba($secondary, 1) 100%
        ), 
            url('../../assets/images/background-shapes.png');
        //background-image: radial-gradient(farthest-corner at 50% 0, transparent 0, #313131 55%), url('../../assets/images/background-shapes.png');
        
    }
    .main{
        position: relative;
    }
    .layout__footer{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 20%;
        background-repeat: repeat-x;
        background-image: linear-gradient(to bottom, 
        rgba($secondary, 1) 0,
        rgba($secondary, .8) 100%
            ), 
        linear-gradient(to right,         
            rgba($secondary, 1) 0,
            rgba($secondary, .1) 15%,
            rgba($secondary, .1) 75%,
            rgba($secondary, 1) 100%
        ), 
        url('../../assets/images/background-shapes.png');   
        z-index: 2;
    } 
    .main__content{
        z-index: 5;
        position: relative;
    }
  }
//background-image: radial-gradient(farthest-corner at 50% 0, transparent 0, #313131 65%), url(/static/media/background-shapes.4b00ee52.png);
.firebase-emulator-warning {
    display: none;
}

