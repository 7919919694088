.qr-code{
    background-image: url('../../assets/images/qr-logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;


    & canvas{
        width: 100%;
    }
    &-hidebg{
        background-image: none;
        background-color: #fff;
        border-radius: 20px;
        padding: 1.3rem;
        & .qr-code__content{
            padding: 0;
            width: 100%;
        }
    }
    &__content{
        width: 75%;
        height: 50%;
        padding: 20%;
        margin: auto;
    }
}