.section-header{
    position: relative;
    margin: 1.5rem 0;
    &__title{
        font-style: normal;
        font-weight: 700;
        text-align: center;

    }
    &__btn{
        position: absolute;
        right: 0;
        top: 0;
    }
}