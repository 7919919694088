.timer{
    font-weight: 700;
    font-size: 1.85rem;
    background: $primary;
    padding: 1.15rem 1.75rem;
    border-radius: 0.55rem;
    border: 2px solid #1F1F1F;
    background: linear-gradient(180deg, #1D1D1D 0%, #333333 100%);
}
.invalid-feedback{
    // color: $red;
    // background: linear-gradient(180deg, #FF5B5B 0%, #AF2000 100%);
    // padding: .15rem;
    color: #fff;
    // border-radius: 0.15rem;
}