.stepper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    &__item{
        display: flex;
        justify-content: space-around;
        width: 100%;
        padding-bottom: 4rem;
        &:last-child{
            & .stepper__count{
                &::after{
                    display: none;
                }
            }
        }
    }
    &__count{
        flex-basis: 50%;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 1px;
            height: 100%;
            background: $primary;
        }
        
    }
    &__num{
        width: 57px;
        height: 57px;
        border-radius: 50%;
        border: 2px solid $primary;
        font-size: 1.71rem;
        text-align: center;
        line-height: 57px;
        margin: auto;
    }
    &__title{
        width: 75%;
        text-align: left;
        flex-basis: 100%;
        font-size: 1.14rem;
    }
}