.content-section{
    position: relative;
    padding-top: 1.5rem;
    &-bg{
        background-image: url('../../assets/images/background-shapes.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center 0;
        background-color: $secondary;
    }
    & h2 {
        font-size: 1.8rem;
        margin-bottom: 2.5rem;
    }
    & p {
        font-size: 1.17rem;
    }
    &__topgradient{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        min-height: 75%;
        background: linear-gradient(180deg, rgba(0, 126, 81, 0.9) 0%, rgba(49, 57, 53, 0.52) 86.05%, rgba(52, 52, 52, 0) 100%);
        z-index: 1;
    }
    &__bottomgradient{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        min-height: 30%;
        background: linear-gradient(180deg, rgba($secondary, 0) 0%, $secondary 100%);
        z-index: 1;
    }
    &__content{
        position: relative;
        z-index: 2;
        padding: 0 1rem;
    }
}

@media screen and (min-width: $md-brakpoint) {
    .content-section{
        position: relative;
        &-bg{
            background-image: linear-gradient(to right,         
            rgba($secondary, 1) 0,
            rgba($secondary, 0) 35%,
            rgba($secondary, 0) 55%,
            rgba($secondary, 1) 100%
        ), url('../../assets/images/background-shapes.png');
            background-repeat: repeat;
            background-size: contain;
            background-position: center 0;
            background-color: $secondary;
        }
    }
}