.modal-content{
    background: linear-gradient(180deg, #007E51 0%, #313131 100%);
    border: 1px solid;
    border-image-source: linear-gradient(180deg, #02A56A 0%, #017E51 100%);
    border-radius: 8px;
}
.modal-header{
    border: none;
    justify-content: center;
    position: relative;
    padding-bottom: 0;
    margin-bottom: 1rem;
    & .close{
        position: absolute;
        right: .6rem;
        top: .4rem;
        color: #fff;
        opacity: 1;
    }
}
.modal-title{
    font-size: 1.28rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.14rem;
    letter-spacing: 0px;
    text-align: center;

}