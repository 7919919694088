//colors
$primary: #10E9C3;
$secondary: #313131;
$white: #fff;
$grey: #3b3b3b;
$red: #AF2000;

$body-color: #ffffff;

$navbar-light-color: $body-color;
$navbar-light-active-color: $body-color;
$navbar-brand-font-size: 1.5rem;


$font-size-base: 1rem;
$headings-font-weight: 600;
$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base;

$input-border-color: transparent;
$input-focus-border-color: transparent;
$input-box-shadow: none;
$input-focus-box-shadow: none;

$btn-border-width: 0;

$md-brakpoint: 768px;