.fee-block{
    padding: 1.3rem 0;
    &__title{
        font-weight: bold;
        font-size: 1.5rem;

    }

    &__content{
        background: linear-gradient(180deg, #008C7B 0%, #01C2A0 100%);
        border-radius: 10px;
        width: 180px;

        font-weight: bold;
        font-size: 1.6rem;
        line-height: 2rem;
        text-align: center;
        padding: 1.07rem 1.15rem;
        margin: auto;
    }
}