.form-control{
    border-radius: 8px;
    padding: 1rem 1.2rem;
    height: calc(1.5em + 2rem + 2px);
    font-size: 1.14rem;
}
.form-label{
    font-size: 1.14rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.14rem;
    letter-spacing: 0px;

}