.user-info {
    .user-avatar {
        display: inline-block;
        position: relative;
        background: url('../../assets//images/avatar-flag.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        padding: 3rem;
        // bg
        .user-action {
            display: inline-block;
            position: absolute;
            right: 3rem;
            bottom: 3rem;
        }
        &-loading{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

        }
        & img{
            border: 2px solid #fff;
        }
    }
    .user-name {
        margin: 0.5em 0;   
    }
}
