.payment-item {
    display: flex;
    justify-content: space-between;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    color: $body-color;
    text-align: center;
    text-decoration: if($link-decoration == none, null, none);
    white-space: $btn-white-space;
    vertical-align: middle;
    user-select: none;
    background-color: $grey;
    padding: .6rem .85rem;
    @include transition($btn-transition);
    margin-bottom: 0.25em;
    border-radius: 8px;
    font-size: 1.28rem;
    &__amount{
        color: $primary;
        font-weight: 500;
    }
}




// LAYOUT
html,body{
    min-height:100%;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
}
#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    & > header {
        flex: 0 0 auto;
    }
    & > main {
        flex: 1 1 auto;
        padding-bottom:3em;
    }

}

// NAV
.sidebar {
    display: none;
    &.active {
        position: fixed;
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 5;
        background-color: rgba($color: #FFF, $alpha: 0.9);
        .sidebar-wrapper {
            position: relative;
            left: 0;
            height: 100%;
            min-width: 240px;
            max-width: 85%;
            background-color: $secondary;
            
        }
    }
}


.navbar {
    .navbar-brand {
        margin: 0 0;
        font-weight: 600;
    }
    .navbar-brand, .navbar-nav-left, .navbar-nav-right {
        min-width: 46px;
    }

}

// CHECKOUT
.stripe-checkout {
    .stripe-checkout-card {
        width: 100%;
        padding: 1em 0;
        background-color: $white;
        border-radius: 4px;
        & > div {
            width: 100%;
            padding: 8px 12px;
        }
    }
}

hr{
    border-color: rgba($body-color, .1);
}

.img-bg{
    background: linear-gradient(180deg, rgba(0, 210, 173, 0.38) 0%, rgba(1, 194, 160, 0) 100%);
    padding: .78rem;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    &-light{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 110%;
        height: 110%;
        transform: translate(-50%, -50%);
        background: linear-gradient(180deg, rgba(0, 194, 255, 0.38) 0%, rgba(1, 194, 160, 0) 100%);
        filter: blur(60px);
        border-radius: 50%;
        z-index: 1;
      
    }
    & img{
        position: relative;
        z-index: 2;
    }
}
// div{
//     font-size: 1.14rem;
// }


// @media screen and (min-width: 900px) {
//     .main{
//         background-image: url('../assets/images/background-shapes.png');
//         background-repeat: repeat-x;
//         // background-size: contain;
//         background-position: center bottom;
//         background-color: $secondary;
//         position: relative;
//     }
//   }

.alert{
    & a {
        color: inherit;
        font-weight: bold;
        text-decoration: underline;
    }
}