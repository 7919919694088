.btn{
    padding: 0.875rem 0.75rem;
    font-size: 1.4rem;
    border-radius: 8px;
    font-weight: 700;
    &.btn-link{
        color: $body-color;
        text-decoration: underline;
    }
    &.btn-primary{
        background: linear-gradient(180deg, #01C2A0 0%, #008C7B 100%);
        color: $white;
        border: 1px solid transparent;
        &.btn-bordered{
            border: 1px solid #C1DDD9;
        }
        &.btn-selected{
            background: linear-gradient(180deg, #078F3E 0%, #00EB6C 100%);
            border: 2px solid #86FFA8;
            box-sizing: border-box;
            box-shadow: 0px 0px 14px #01C551;
            border-radius: 8px;
        }
    }
    &.btn-secondary{
        background: linear-gradient(180deg, #3D3D3D 0%, #6C6C6C 100%);
        border: 1px solid #808080;
    }
    &.btn-info{
        background: linear-gradient(180deg, #0194C2 0%, #00628C 100%);  
    }
    &.btn-block {
        position: relative;
        overflow: hidden;
        & .icon{
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            font-size: 2rem;
        }
        & .remove{
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 3.5rem;
            background: linear-gradient(180deg, #FF5B5B 0%, #AF2000 100%);
            font-size: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.btn-icon {
    width: 46px;
    height: 46px;
    text-align: center;
    padding: 0px;
    border-radius: 50%;

    & > * {
        color: $body-color;
        display: inline-block;
        width: 20px;
        height: 20px;
    }

    &.btn-sm {
        width: 36px;
        height: 36px;
        text-align: center;
        padding: 0px;
        border-radius: 50%;

        & > * {
            color: $body-color;
            display: inline-block;
            width: 20px;
            height: 20px;
        }
    }
}